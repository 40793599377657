import { useMemo } from 'react';
import { useTranslation } from '~i18n';

function useGetPreferredLanguages() {
  const { t } = useTranslation('public');
  return [
    {
      value: 'EN',
      label: t('preferred_language.EN'),
    },
    {
      value: 'CA',
      label: t('preferred_language.CA'),
    },
    {
      value: 'ES',
      label: t('preferred_language.ES'),
    },
    {
      value: 'DE',
      label: t('preferred_language.DE'),
    },
    {
      value: 'SC',
      label: t('preferred_language.SC'),
    },
  ];
}

function useGetDefaultLanguageOption(lang: string) {
  const preferredLanguages = useGetPreferredLanguages();
  return useMemo(() => {
    if (!preferredLanguages) {
      return null;
    }
    const language = preferredLanguages?.find(item => {
      return item?.value?.toLocaleLowerCase() === lang;
    });
    if (language) {
      return {
        value: language.value,
        label: language.label,
      };
    }
    if (lang === 'zh-cn') {
      return preferredLanguages?.find(item => item?.value === 'SC');
    }
    return {
      value: 'EN',
      label: 'English(UK)',
    };
  }, [lang, preferredLanguages]);
}

export { useGetDefaultLanguageOption, useGetPreferredLanguages };
