import { useLazyQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  SP_QueryFieldSettingByModel,
  SP_QueryFieldSettingByModelVariables,
} from '~graphql/__generated__/SP_QueryFieldSettingByModel';
import { QUERY_FIELD_SETTINGS_BY_MODEL } from '~graphql/person-detail';
import { useTranslation } from '~i18n';

export interface OptionProps {
  label: string | null | undefined;
  value: string | null | undefined;
}
export interface PersonDropdownOptionsProps {
  titleOptions: (OptionProps | null)[];
  heardSourceOptions: (OptionProps | null)[];
  degreeClassificationOptions: (OptionProps | null)[];
  yearOfStudyOptions: (OptionProps | null)[];
  genderPronounOptions: (OptionProps | null)[];
}

const usePersonDropdownOptions = ({
  variables,
}: {
  variables: SP_QueryFieldSettingByModelVariables;
}) => {
  const { t } = useTranslation(['student_portal']);

  const [queryPersonDropdownOptions, { data }] = useLazyQuery<
    SP_QueryFieldSettingByModel,
    SP_QueryFieldSettingByModelVariables
  >(QUERY_FIELD_SETTINGS_BY_MODEL, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const personDropdownOptions = data?.getFieldSettingsByModel;

  const titleOptions: (OptionProps | null)[] = useMemo(() => {
    const picklistOptions = personDropdownOptions?.find(
      item => item?.fieldName?.toLowerCase() === 'title'
    )?.picklistOptions;

    return (
      picklistOptions?.map(option => ({
        label: option?.name,
        value: option?.value,
      })) || []
    );
  }, [personDropdownOptions]);

  const heardSourceOptions: (OptionProps | null)[] = useMemo(() => {
    const picklistOptions = personDropdownOptions?.find(
      item => item?.fieldName?.toLowerCase() === 'heard_source'
    )?.picklistOptions;

    return (
      picklistOptions?.map(option => ({
        label: t(`create_account.heardSource.${option?.value?.toLowerCase()}`),
        value: option?.value,
      })) || []
    );
  }, [personDropdownOptions, t]);

  //Degree Classification
  const degreeClassificationOptions: (OptionProps | null)[] = useMemo(() => {
    const picklistOptions = personDropdownOptions?.find(
      item => item?.fieldName?.toLowerCase() === 'degree_classification'
    )?.picklistOptions;

    return (
      picklistOptions?.map(option => ({
        label: t(`form.options.degree_classification.${option?.value?.toLowerCase()}`),
        value: option?.value,
      })) || []
    );
  }, [personDropdownOptions, t]);

  const yearOfStudyOptions: (OptionProps | null)[] = useMemo(() => {
    const picklistOptions = personDropdownOptions?.find(
      item => item?.fieldName?.toLowerCase() === 'year_of_study'
    )?.picklistOptions;

    return (
      picklistOptions?.map(option => ({
        label: t(`form.options.study_year.${option?.value?.toLowerCase()}`),
        value: option?.value,
      })) || []
    );
  }, [personDropdownOptions, t]);

  const genderPronounOptions: (OptionProps | null)[] = useMemo(() => {
    const picklistOptions = personDropdownOptions?.find(
      item => item?.fieldName?.toLowerCase() === 'preferred_gender_pronoun'
    )?.picklistOptions;

    return (
      picklistOptions?.map(option => ({
        label: t(`pronoun.option.${option?.value?.toUpperCase()}`),
        value: option?.value,
      })) || []
    );
  }, [personDropdownOptions, t]);

  const topLandlordId = variables?.topLandlordId;
  const model = variables?.model;

  useEffect(() => {
    if (model && topLandlordId) {
      queryPersonDropdownOptions();
    }
  }, [model, queryPersonDropdownOptions, topLandlordId]);

  return {
    titleOptions,
    heardSourceOptions,
    yearOfStudyOptions,
    degreeClassificationOptions,
    genderPronounOptions,
  };
};

export { usePersonDropdownOptions };
