import React from 'react';
import Button from '~components/button';
import Loading from '~components/button/loading';
import { Input } from '~components/form';
import Typography from '~components/typography/typography';
import { useTranslation } from '~i18n';
import { VerificationRefresh, VerificationUnableImage } from '~svg-components';

const VerificationModalContent = (props: any) => {
  const { t } = useTranslation(['student_portal', 'public']);
  const { data, loading, buttonProps, hasError, onRefresh, isPopupVersion, property } = props;
  return (
    <div className="validate-modal-wrapper">
      <div style={{ textAlign: 'center', marginBottom: isPopupVersion ? 24 : 32 }}>
        <Typography variant="body3" color="var(--color-tundora)">
          {t('student_portal:verification_to_create_account')}
        </Typography>
      </div>

      <div className="validate-svg-wrapper">
        {data && !loading && !hasError && (
          <div className="validate-svg-container" dangerouslySetInnerHTML={{ __html: data }}></div>
        )}
        {!data && (
          <div className="validate-svg-container">
            {loading && <Loading />}
            {hasError && !loading && <VerificationUnableImage className="validate-error" />}
          </div>
        )}
        <VerificationRefresh className="validate-refresh" onClick={onRefresh} />
      </div>

      <div className="validate-form-wrapper">
        {hasError && (
          <div style={{ textAlign: 'left', marginTop: 8 }}>
            <Typography variant="body3" color="var(--color-red_6)">
              {t('student_portal:unable_to_generate_image_plz_contact_residence_team', {
                email: property?.contactEmailAddress,
                areaCode: property?.contactAreaCode,
                phone: property?.contactPhoneNumber,
              })}
            </Typography>
          </div>
        )}
        <Input
          name="validationToken"
          placeholder={t('public:enter_code_above')}
          className="validate-input"
        />
        <Button
          fullWidth
          type="submit"
          {...buttonProps}
          color={isPopupVersion ? 'secondary' : 'primary'}
          size={isPopupVersion ? 'default' : 'large'}
        >
          {t('public:submit')}
        </Button>
      </div>

      <style jsx>{`
        :global(.validate-input) {
          margin: ${isPopupVersion ? '11px 0 19px' : '11px 0 27px'};
        }
        :global(.validate-refresh) {
          width: ${isPopupVersion ? '16px' : '24px'};
          height: ${isPopupVersion ? '16px' : '24px'};
          fill: var(--color-yellow_6);
          margin-left: ${isPopupVersion ? '16px' : '24px'};
          cursor: pointer;
        }
        :global(.validate-error) {
          height: 28px;
          width: 25px;
          fill: var(--color-greyText);
        }
        .validate-svg-container {
          width: ${isPopupVersion ? '163px' : '216px'};
          height: ${isPopupVersion ? '46px' : '61px'};
          background: var(--color-background);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .validate-svg-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .validate-form-wrapper {
          max-width: 400px;
          margin: 0 auto;
        }
      `}</style>
    </div>
  );
};

export default VerificationModalContent;
